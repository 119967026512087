<template>
  <v-dialog v-model="dialog" style="height: auto" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        class="ma-1"
        elevation="0"
        outlined
        :style="'width: 100%; height: 30px'"
        ><v-icon small left>$clip</v-icon>
        <span style="color: white; text-transform: normal !important">{{
          $t("import")
        }}</span>
      </v-btn>
    </template>
    <v-card id="uploadFileCard">
      <form
        action
        data-vv-scope="design-form"
        @submit.prevent="validateForm('design-form')"
      >
        <v-card-title
          style="text-transform: uppercase; text-decoration: underline"
          >{{ $t("attach", { name: $tc("files") }) }}
        </v-card-title>
        <a
          v-if="active !== null"
          class="link"
          :href="
            type != 'tattooer'
              ? type != 'customer'
                ? type != 'inventory'
                  ? type != 'movement'
                    ? '/Gastos Quickink.xlsx'
                    : '/Movimientos Quickink.xlsx'
                  : '/Inventario Quickink.xlsx'
                : '/Clientes Quickink.xlsx'
              : '/Artistas Quickink.xlsx'
          "
          download
          ><v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn color="primary" v-on="on" @click="saveTemplate" icon>
                <v-icon size="24"> $download </v-icon>
              </v-btn>
            </template>
            {{ $t("download_template") }}
          </v-tooltip></a
        >
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text class="pb-1">
          <v-row style="display: flex; justify-content: center">
            <v-col cols="12" md="9" class="pt-4 pb-0">
              <div
                v-if="active === null"
                style="min-height: 50px; color: var(--v-primary-base)"
              >
                <h4 class="message pt-3" style="font-weight: normal">
                  {{ $t("designs.first_import.h4") }}
                </h4>
                <ol class="ml-3 mt-1">
                  <li v-for="i in 4" :key="i">
                    {{ $t("designs.first_import.li_" + i) }}
                  </li>
                </ol>
              </div>
              <file-upload
                v-else-if="active !== null && dialog"
                style=""
                :multiple="false"
                :drop="true"
                :drop-directory="true"
                accept=".xls,.xlsx"
                v-model="files"
                ref="upload"
                class="pt-2 file-upload"
              >
                <div
                  style="
                    border: dashed var(--v-primary-base) 3px;
                    border-radius: 10px;
                    min-height: 50px;
                    text-align: center;
                    color: var(--v-primary-base);
                  "
                >
                  <div v-if="files.length" style="height: 100%">
                    <template v-for="(file, i) in files">
                      <v-chip
                        v-if="i < 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        style="
                          max-width: 30%;
                          vertical-align: middle;
                          margin-top: 6px;
                        "
                        class="no-wrap mx-1"
                        >{{ file.name }}
                      </v-chip>
                      <v-chip
                        v-if="i == 2"
                        :key="file.id"
                        outlined
                        color="primary"
                        class="mx-1"
                        style="margin-top: 6px"
                      >
                        + {{ files.length - 2 }}
                        {{ $tc("file", files.length - 2) }}
                      </v-chip>
                    </template>
                  </div>
                  <!--Estilo movil-->
                  <div v-if="files.length === 0">
                    <h4 class="message pt-3" style="font-weight: normal">
                      <v-icon size="12" color="primary">$clip</v-icon>
                      {{ $t("designs.first_import.message") }}
                    </h4>
                  </div>

                  <div class="example-btn"></div>
                </div>
              </file-upload>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-4">
          <v-row
            v-if="active == null"
            style="display: flex; justify-content: center"
            ><a
              :href="
                type != 'tattooer'
                  ? type != 'customer'
                    ? type != 'inventory'
                      ? type != 'movement'
                        ? '/Gastos Quickink.xlsx'
                        : '/Movimientos Quickink.xlsx'
                      : '/Inventario Quickink.xlsx'
                    : '/Clientes Quickink.xlsx'
                  : '/Artistas Quickink.xlsx'
              "
              download
              ><v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="primary"
                    icon
                    class="mr-2 pb-2"
                    style="margin: 0 auto"
                    @click="saveTemplate"
                    v-on="on"
                  >
                    <v-icon size="30"> $download </v-icon>
                  </v-btn>
                </template>
                {{ $t("download_template") }}
              </v-tooltip></a
            >
          </v-row>
          <v-row v-else class="pr-3">
            <div class="flex-grow-1"></div>
            <v-btn
              outlined
              @click="
                dialog = false;
                files = [];
              "
              style="height: 25px; width: 100px"
              elevation="0"
              class="mr-2"
              >{{ $t("cancel") }}</v-btn
            >
            <v-btn
              type="submit"
              style="
                width: 100px !important;
                color: #363533;
                height: 25px;
                padding-top: 10px !important;
              "
              elevation="0"
            >
              <v-icon style="margin-right: 5px" size="14px">$save</v-icon>
              {{ $t("import", { name: $t("") }) }}
            </v-btn>
          </v-row>
        </v-card-actions>
      </form>
    </v-card>
    <v-dialog v-model="alert" max-width="500px"
      ><v-card id="uploadFileCard">
        <v-card-title
          style="text-transform: uppercase; text-decoration: underline"
          >{{ $tc("error", 2) }}
        </v-card-title>
        <v-card-text class="pb-1"
          ><v-row
            ><v-col
              :style="
                action !== 'artist'
                  ? 'max-height: 200px;overflow-y: scroll;min-height: 50px;text-align: center;color: var(--v-primary-base);'
                  : 'min-height: 50px;text-align: center;color: var(--v-primary-base);'
              "
              >{{ message }}
              <p v-for="(key, i) in message_error" :key="i">{{ key }}</p>
            </v-col></v-row
          >
        </v-card-text>
        <v-card-actions class="pr-4">
          <v-row class="pr-3">
            <div class="flex-grow-1"></div>
            <div v-if="action == 'artist'">
              <v-btn
                @click="
                  alert = false;
                  alertCheck('yes');
                "
                style="height: 25px; width: 100px"
                elevation="0"
                class="mr-2"
                >{{ $t("yes") }}</v-btn
              >
              <v-btn
                outlined
                style="
                  width: 100px !important;
                  color: #363533;
                  height: 25px;
                  padding-top: 10px !important;
                "
                elevation="0"
                @click="
                  alert = false;
                  alertCheck('no');
                "
              >
                {{ $t("no") }}
              </v-btn>
              <v-btn
                outlined
                @click="alert = false"
                style="height: 25px; width: 100px"
                elevation="0"
                class="mr-2"
                >{{ $t("cancel") }}</v-btn
              >
            </div>
            <div v-else>
              <v-btn
                outlined
                @click="alert = false"
                style="height: 25px; width: 100px"
                elevation="0"
                class="mr-2"
                >{{ $t("close") }}</v-btn
              >
            </div>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import FileUpload from "vue-upload-component";
import { mapActions } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      files: [],
      alert: false,
      message: null,
      message_error: [],
      action: null,
      active: null,
    };
  },
  watch: {
    dialog: {
      handler() {},
      deep: true,
    },
  },
  props: ["route", "type", "valor"],
  components: { FileUpload },
  mounted() {
    this.fetchSetting();
    //TODO Comprovar que se guarde bien el archivo mediante buscador no arrastrando
  },
  methods: {
    ...mapActions("tattooers", ["addTattooerExcel", "checkExcel"]),
    ...mapActions("customers", ["addCustomerExcel"]),
    ...mapActions("expenses", ["addExpenseExcel"]),
    ...mapActions("users", ["settingUpdate", "getSetting"]),
    ...mapActions("inventory", ["addInventoryExcel"]),
    ...mapActions("inventorymovements", ["addMovementExcel"]),

    fetchSetting() {
      this.loading = true;
      this.getSetting({ key: `exp-imp_${this.valor}` }).then((boxes) => {
        //console.log(boxes.data);

        this.active = boxes.data.value;
        //console.log(this.active);
        this.loading = false;
      });
    },
    saveTemplate() {
      this.settingUpdate({
        key: `exp-imp_${this.valor}`,
        value: this.valor,
        json: true,
      }).then((data) => {
        //console.log(data);
        this.fetchSetting();
      });
    },
    validateForm(scope) {
      this.errors.clear();
      this.userProcessed = false;
      //console.log(this.route);
      this.$validator.validateAll(scope).then((result) => {
        if (result & (this.files.length > 0)) {
          let formData = new FormData();

          this.files.forEach((file) => {
            formData.append("excel", file.file);
          });
          if (this.route !== null) formData.append("route", this.route);
          switch (this.type) {
            case "tattooer":
              //console.log(this.type);
              this.checkExcel(formData).then((data) => {
                if (data.errors.length == 0 && data.errorsExcel.length == 0) {
                  this.alertCheck("no");
                } else if (
                  data.errors.length > 0 &&
                  data.errorsExcel.length == 0
                ) {
                  let value = data.errors;
                  let s = "";
                  //console.log(value);
                  for (let i = 0; i < value.length; i++) {
                    if (i < 2) {
                      s += `\n${value[i][2]},`;
                    }
                  }
                  if (value.length > 2) {
                    s += `\n +${value.length - 2}\n`;
                  }

                  this.message = this.$t("exp_imp.alert_tattooer", { name: s });
                  this.alert = true;
                  this.action = "artist";
                } else {
                  this.$alert(
                    this.$t("exp_imp.error_emails_excel"),
                    "",
                    ""
                  ).then(() => {
                    this.files = [];
                  });
                }
              });

              break;
            case "customer":
              //console.log(formData);
              this.addCustomerExcel(formData).then((data) => {
                if (data.success) {
                  if (data.errors.length > 0) {
                    this.message = this.$t("exp_imp.alert_movement");
                    this.message_error = data.errors;
                    this.dialog = false;
                    this.alert = true;
                    this.action = "customer";
                    this.$emit("update", data);
                  } else {
                    console.log(data);
                    this.dialog = false;
                    this.$emit("update", data);
                    this.$alert("Importado correctamente");
                    this.files = [];
                  }
                } else {
                  this.$alert(this.$t("exp_imp.error_type_excel"), "", "").then(
                    () => {
                      this.files = [];
                    }
                  );
                }
              });
              break;
            case "expense":
              //console.log(this.type);
              this.addExpenseExcel(formData).then((data) => {
                if (data.success) {
                  if (data.errors.length > 0) {
                    this.message = this.$t("exp_imp.alert_movement");
                    this.message_error = data.errors;
                    this.dialog = false;
                    this.alert = true;
                    this.action = "expense";
                    this.$emit("update", data);
                  } else {
                    console.log(data);
                    this.dialog = false;
                    this.$emit("update", data);
                    this.$alert("Importado correctamente");
                    this.files = [];
                  }
                } else {
                  this.$alert(this.$t("exp_imp.error_type_excel"), "", "").then(
                    () => {
                      this.files = [];
                    }
                  );
                }
              });
              break;
            case "inventory":
              //console.log(this.type);
              this.addInventoryExcel(formData).then((data) => {
                if (data.success) {
                  this.dialog = false;
                  this.$emit("update", data);
                  this.$alert("Importado correctamente");

                  this.files = [];
                } else {
                  this.$alert(this.$t("exp_imp.error_type_excel"), "", "").then(
                    () => {
                      this.files = [];
                    }
                  );
                }
              });
              break;
            case "movement":
              //console.log(this.type);
              this.addMovementExcel(formData).then((data) => {
                if (data.success) {
                  if (data.errors.length > 0) {
                    this.message = this.$t("exp_imp.alert_movement");
                    this.message_error = data.errors;
                    this.dialog = false;
                    this.alert = true;
                    this.action = "movement";
                    this.$emit("update", data);
                  } else {
                    console.log(data);
                    this.dialog = false;
                    this.$emit("update", data);
                    this.$alert("Importado correctamente");
                    this.files = [];
                  }
                } else {
                  this.$alert(this.$t("exp_imp.error_type_excel"), "", "").then(
                    () => {
                      this.files = [];
                    }
                  );
                }
              });
              break;
            default:
              break;
          }
        }
      });
    },

    alertCheck(value) {
      if (this.files.length > 0) {
        let formData = new FormData();

        this.files.forEach((file) => {
          formData.append("excel", file.file);
        });
        if (this.route !== null) formData.append("route", this.route);
        switch (this.type) {
          case "tattooer":
            //console.log(this.type);
            this.addTattooerExcel({ formData, option: value }).then((data) => {
              this.dialog = false;
              this.$emit("update", data);
              this.$alert("Importado correctamente");
              this.files = [];
            });

            break;
          case "customer":
            //console.log(this.route);
            this.addCustomerExcel(formData).then((data) => {
              this.dialog = false;
              this.$emit("update", data);
              this.$alert("Importado correctamente");
              this.files = [];
            });
            break;
          case "expense":
            //console.log(this.type);
            this.addExpenseExcel(formData).then((data) => {
              this.dialog = false;
              this.$emit("update", data);
              this.$alert("Importado correctamente");
              this.files = [];
            });
            break;
          case "inventory":
            //console.log(this.type);
            this.addInventoryExcel(formData).then((data) => {
              this.dialog = false;
              this.$emit("update", data);
              this.$alert("Importado correctamente");
              this.files = [];
            });
            break;
          default:
            break;
        }
      }
    },
  },
};
</script>
<style lang="sass">
.v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab)
  padding: 10px 15px !important
.file-upload
  width: 100%
  min-height: 50px

.upload
  min-height: 100px
  border-radius: 10px
  border: dashed grey 2px
  text-align: center
  .message
    padding-top: 15px
#uploadFileCard
  width: 500px

  .v-btn__content
    padding-bottom: 2px
  .link
    position: absolute
    top: 0px
    left: 0px

@media (max-width: 400px)

  .file-upload
    min-height: 100px
</style>
